<template>
    <div class="dashboard-announcement">
        <div v-if="isLoading" class="process-loading-icon" />
        <div class="dashboard-announcement-title">
            <div class="dashboard-title">重要公告</div>
            <a
                :href="currentTypeLink"
                class="dashboard-link"
                target="_blank"
                @click="
                    this.$setGaEvent(`targetKm${currentMenu}Url`, 'click-link')
                "
            >
                <div class="link-name">查看更多</div>
                <div class="link-imagebox">
                    <img
                        src="@/assets/images/icon_rightarrow_white@3x.webp"
                        alt="跳轉全部連結按鈕"
                    />
                </div>
            </a>
        </div>
        <TagMenu
            :isDashboard="true"
            v-model="currentMenu"
            :options="getAnnouncementTab"
        />
        <div class="dashboard-announcement-row" v-if="!isLoading">
            <div v-if="getAnnouncementAPI && !isLoading">
                <DashboardModal
                    modalTitle="其他通知事項"
                    :title="apiCompany"
                    :files="[apiFile]"
                    :content="apiContent"
                    dateTitle="最後更新日期"
                    :date="apiTime"
                    v-model:isShowModalProp="isShowModal"
                    @close="turnOffModal"
                />
                <div
                    :class="['row-box']"
                    v-for="(data, index) in switchAnnouncementType.list"
                    :key="index"
                >
                    <div class="row-title">
                        <div class="time">
                            {{ isLoading ? '' : data.updatedAt }}
                        </div>
                        <span v-if="!isLoading">|</span>
                        <div class="company">
                            {{ isLoading ? '' : data.company }}
                            {{ isLoading ? '' : data.title }}
                        </div>
                    </div>
                    <div class="row-link" v-if="!isLoading">
                        <a @click="showAnnouncementContent(data)">
                            <img
                                src="@/assets/images/icon_downloadcontent@3x.webp"
                                alt="fake"
                            />
                        </a>
                    </div>
                </div>
                <DashboardNoDataVue
                    v-if="!isLoading && !hasAnnouncement"
                    message="暫時沒有近兩週的公告"
                />
            </div>
            <DashboardErrorMessage
                v-if="!getAnnouncementAPI && !isLoading"
                :hasPermission="$store.state.userInfo?.hasExternalPerm"
                functionName="getImportantAnnouncementAPI"
                @reacquire="getImportantAnnouncementAPI"
            />
        </div>
    </div>
</template>
<script>
import DashboardNoDataVue from './DashboardNoData.vue'
import DashboardErrorMessage from './DashboardErrorMessage.vue'
import DashboardModal from './DashboardModal.vue'
import TagMenu from '../../components/TagMenu.vue'

import { getDashboardAnnouncementAPI } from '../../assets/javascripts/api'
export default {
    name: 'DashboardAnnouncement',
    components: {
        DashboardNoDataVue,
        DashboardErrorMessage,
        DashboardModal,
        TagMenu
    },
    methods: {
        getImportantAnnouncementAPI: async function () {
            this.isLoading = true
            try {
                if (!this.$store.state.userInfo?.hasExternalPerm)
                    throw new Error('no permission')
                const res = await getDashboardAnnouncementAPI()
                if (res.data) {
                    this.getAnnouncementAPI = res.data
                } else {
                    throw new Error()
                }
            } catch (error) {
                this.getAnnouncementAPI = null
            } finally {
                this.isLoading = false
            }
        },
        turnOffModal: function () {
            this.isShowModal = false
        },
        showAnnouncementContent: function (data) {
            this.$setGaEvent(`showAnnouncementContent`, 'click-icon')
            this.isShowModal = !this.isShowModal
            this.apiContent = data.content

            this.apiTime = data.updatedAt
            this.apiCompany = data.company
            this.apiFile = data.file
            this.apiLink = data.file.link
        }
    },
    computed: {
        switchAnnouncementType: function () {
            switch (this.currentMenu) {
                default: {
                    return this.getAnnouncementAPI?.weeklyBriefings
                }
                case 1: {
                    return this.getAnnouncementAPI?.weeklyTrainings
                }
                case 2: {
                    return this.getAnnouncementAPI?.headquartersDocuments
                }
                case 3: {
                    return this.getAnnouncementAPI?.otherNotifies
                }
            }
        },
        hasAnnouncement: function () {
            if (this.switchAnnouncementType?.list?.length > 0) {
                return true
            }
            return false
        },
        getAnnouncementTab: function () {
            if (this.getAnnouncementAPI !== null) {
                let apiDatas = this.getAnnouncementAPI
                let apiKeys = Object.keys(apiDatas)
                let tabList = []
                let showTabAmount = 4
                for (let index = 0; index <= showTabAmount; index++) {
                    switch (apiKeys[index]) {
                        case 'month': {
                            break
                        }
                        case 'weeklyBriefings': {
                            tabList.push({
                                value: 0,
                                label: '行政管理部週報'
                            })
                            break
                        }
                        case 'weeklyTrainings': {
                            tabList.push({
                                value: 1,
                                label: '業務體系週報'
                            })
                            break
                        }
                        case 'headquartersDocuments': {
                            tabList.push({
                                value: 2,
                                label: '磊山公文'
                            })
                            break
                        }
                        case 'otherNotifies': {
                            tabList.push({
                                value: 3,
                                label: '其他通知事項'
                            })
                        }
                    }
                }
                return tabList
            } else {
                return [
                    {
                        value: 0,
                        label: '行政管理部週報'
                    },
                    {
                        value: 1,
                        label: '業務體系週報'
                    },
                    {
                        value: 2,
                        label: '磊山公文'
                    },
                    {
                        value: 3,
                        label: '其他通知事項'
                    }
                ]
            }
        },
        currentTypeLink: function () {
            if (this.$store.state.dashboardAnnouncement !== null) {
                return this.switchAnnouncementType?.categoryLink
            }
            return 'https://km.leishan.app/'
        }
    },
    watch: {
        currentMenu: function (newVal) {
            this.$setGaEvent(`selectAnnouncement${newVal}`, 'toggle-menu')
        }
    },
    data() {
        return {
            currentMenu: 0,
            menus: ['週例會簡報', '培訓週報', '總公司公文', '其他通知事項'],
            isShowModal: false,
            apiContent: '',
            apiTime: '',
            apiCompany: '',
            apiLink: '',
            apiFile: '',
            isLoading: false,
            linkList: [
                'https://km.leishan.app/',
                '##',
                'https://www.google.com/'
            ],
            getAnnouncementAPI: null
        }
    },
    created() {
        this.getImportantAnnouncementAPI()
    }
}
</script>
<style lang="scss" scoped>
@use "../../assets/styles/common.scss";
@use "../../assets/styles/icon.scss";
@mixin scrollBar() {
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: $sum-row-grey;
        border-radius: 15px;
    }
    &::-webkit-scrollbar-track {
        background: rgba(255, 255, 255, 0.3);
        background: transparent;
    }
    //解決x,y軸交界處產生的異樣白色空區問題
    &::-webkit-scrollbar-corner {
        background: rgba(0, 0, 0, 0);
    }
}
.process-loading-icon {
    position: absolute;
    top: 55%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 30px;
    height: 30px;
}
.dashboard-announcement {
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px 20px;
    overflow: hidden;
    height: 215px;
    position: relative;
    @media screen and(min-width:576px) {
        margin-bottom: 10px;
    }

    @media screen and(max-width:1280px) {
        padding: 10px;
    }

    :deep(.dashboard) {
        display: flex;
        justify-content: flex-start;
        //TODO IOS14.5使用者上升後使用
        // gap: 25px;
        gap: 0;
        margin-bottom: 10px;

        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
        }
        @media screen and (min-width: 576px) {
            display: flex;
            justify-content: center;
            //TODO IOS14.5使用者上升後使用
            // gap: 30px;
        }
        @media screen and (min-width: 768px) {
            display: flex;
            justify-content: center;
            //TODO IOS14.5使用者上升後使用
            // gap: 35px;
        }
        @media screen and(min-width:1280px) {
            //TODO IOS14.5使用者上升後使用
            // gap: 45px;
        }

        .menu-item {
            justify-content: center;

            max-width: 140px;
            //TODO IOS14.5使用者上升後移除
            margin-right: 25px;
            overflow-x: scroll;
            overflow-y: hidden;
            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */

            text-overflow: ellipsis;
            white-space: nowrap;

            &:last-child {
                margin-right: 0;
            }
            &::-webkit-scrollbar {
                display: none;
            }
            @media screen and(min-width:576px) {
                min-width: auto;
                //TODO IOS14.5使用者上升後移除
                margin-right: 30px;
            }
            @media screen and (min-width: 768px) {
                //TODO IOS14.5使用者上升後移除
                margin-right: 35px;
            }
            @media screen and(min-width:1280px) {
                //TODO IOS14.5使用者上升後移除
                margin-right: 45px;
            }
        }
    }
    &-title {
        display: flex;
        align-items: center;
        min-width: 497px;
        box-sizing: border-box;
        margin-bottom: 10px;
        position: relative;
        @media screen and(max-width:1017px) {
            min-width: 497px;
        }
        @media screen and(max-width:576px) {
            min-width: 265px; //模擬325px也不會跑版
        }

        .dashboard-title {
            flex: 1;
            text-align: center;
            margin-bottom: 0;
        }

        .dashboard-link {
            display: flex;
            //TODO IOS 14.5使用者上升後使用
            // gap: 5px;
            align-items: center;
            background: linear-gradient(
                270deg,
                $fourth-blue 18.35%,
                $dashboard-purple 81.94%
            );
            color: white;
            border-radius: 5px;

            padding: 0 10px;
            height: 25px;

            line-height: 17px;
            font-size: 12px;
            box-sizing: border-box;

            position: absolute;
            right: 0%;
            .link-name {
                line-height: 12px;
                //TODO IOS 14.5使用者下降移除
                margin-right: 5px;
            }

            .link-imagebox {
                width: 5px;
                height: 8px;
                align-self: center;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
    }
    &-menu {
        display: flex;
        justify-content: center;
        gap: 25px;
        list-style: none;
        margin-bottom: 10px;

        font-family: 'Noto Sans TC';
        font-style: normal;
        &::-webkit-scrollbar {
            display: none;
        }

        @media screen and(max-width:576px) {
            overflow: scroll;
            justify-content: flex-start;
            gap: 5px;
        }

        li {
            padding: 9px 10px;
            font-size: 15px;
            color: $secondary-grey;
            cursor: pointer;
            white-space: nowrap;
            @media screen and(max-width:576px) {
                width: 75px;
                height: 22px;
            }
        }
    }
    &-row {
        display: flex;
        flex-direction: column;
        overflow-y: scroll;
        padding-right: 10px;
        margin-right: -15px;

        height: 125px;
        @include scrollBar();
        .row-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            align-items: center;
            padding: 0px 10px;
            margin-bottom: 8px;
            gap: 10px;

            box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            @media screen and(max-width:576px) {
                padding: 12px 10px;
                height: 10px;
                font-size: 12px;
            }

            .row-title {
                display: flex;
                font-family: 'Noto Sans TC';
                font-size: 12px;
                font-style: normal;
                color: $secondary-grey;
                gap: 10px;
                .company {
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
            .row-link {
                display: flex;
                align-items: center;
                gap: 20px;
                height: 20px;
                width: 20px;
                cursor: pointer;

                a {
                    display: inline-block;
                    height: 20px;
                    width: 20px;

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }
                .nofile {
                    cursor: pointer;
                    pointer-events: none;
                }
            }
        }
    }

    .focusing {
        border-bottom: 2px solid $fourth-blue;
        color: black;
        font-weight: 500;
    }
}
.row-box {
    background: $primary-white;
}

.preload {
    .text-line {
        @extend .animated-background;
    }
}
.animated-background {
    content: '';
    width: 100%;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: $fourth-grey;
    background: linear-gradient(
        to right,
        $third-grey 8%,
        $secondary-white 18%,
        $third-grey 33%
    );
    background-size: 800px 104px;
    box-sizing: border-box;
}
</style>
